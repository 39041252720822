import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Layout from '../components/layout';

const Row = styled('section')`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

const Hero = styled(Row)`
  min-height: calc(100vh - 12rem);
`;

const Column = styled('div')`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;

  @media (min-width: 768px) {
    padding: 2rem;
  }
`;

const Title = styled('h1')`
  font-size: 4rem;

  @media (min-width: 992px) {
    font-size: 6rem;
  }

  @media (min-width: 1200px) {
    font-size: 8rem;
  }
`;

const List = styled('ul')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
`;

const Item = styled('li')`
  flex-basis: calc(50% - 1rem);
  margin-left: 1rem;
  padding: 1rem 0;
`;

const Copy = styled('p')`
  color: #333;
  font-size: 1rem;
  opacity: 0.5;
`;

const ExternalLink = styled('a')`
  background-color: #333;
  color: #fff;
  display: inline-block;
  margin-top: 1rem;
  padding: 1rem 1.5rem;
  transition: all 0.3s ease-out;
  text-decoration: none;

  &:hover {
    background-color: #000;
  }
`;

const Skills = () => {
  const skillList = [
    {
      name: 'JavaScript',
    },
    {
      name: 'TypeScript',
    },
    {
      name: 'React.js',
    },
    {
      name: 'Redux',
    },
    {
      name: 'Next.js',
    },
    {
      name: 'Node.js',
    },
    {
      name: 'Express',
    },
    {
      name: 'MySQL',
    },
    {
      name: 'Jest',
    },
    {
      name: 'React Testing Library',
    },
    {
      name: 'Git',
    },
  ];

  return (
    <Layout fullWidth={true}>
      <Hero>
        <Column
          css={css`
            /* @media (min-width: 992px) and (max-width: 1200px) { */
            align-items: flex-start;
            /* } */
          `}
        >
          <Title>Skills</Title>
          <Copy>
            Languages, libraries and tools I'm strongest working with.
          </Copy>
          <Copy>Hit me up on LinkedIn for possible cooperation.</Copy>
          <ExternalLink
            href="https://www.linkedin.com/in/norbertkalacska/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Let's chat
          </ExternalLink>
          {/* <a href={CV} target="_blank" download>
            Download CV
          </a> */}
        </Column>
        <Column>
          <List>
            {skillList.map((skill, i) => (
              <Item key={`skill-${i}`}>{skill.name}</Item>
            ))}
          </List>
        </Column>
      </Hero>
    </Layout>
  );
};

export default Skills;
